import Rest from '../Rest'

export default class StudentService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/students'

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }

  createOrUpdateExtended(record: any) {
    if (
      !record.hasOwnProperty('id') ||
      typeof record['id'] === undefined ||
      record['id'] === null ||
      record['id'] === undefined
    ) {
      delete record['id']
      return this.createExtended(record)
    }
    return this.updateExtended(record)
  }

  createExtended(record: any) {
    return this.post('extended', record)
  }

  updateExtended(record: any) {
    return this.put(`extended/${this.getId(record)}`, record)
  }

  readExtended(record: any) {
    return this.get(`extended/${this.getId(record)}`)
  }

  searchByName(term) {
    return this.get(`/search_by_name/${term}`)
  }
}
